import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '30-60 minutes',
  session: '1 session',
  results: 'Results 6-18 months',
}

const questions: Array<Question> = [
  {
    question: 'What are dermal fillers?',
    answer:
      'These are clear hyaluronic acid gels which closely mimic the natural hyaluronic acid' +
      ' in our skin. They are injected just beneath the surface of the skin and are one of the most' +
      ' popular treatments for filling and smoothing fine lines and wrinkles. Hyaluronic acid ' +
      'volumises, softens and hydrates the skin. It also aids collagen and elastin organisation' +
      ' underneath the skin which gives the skin a more youthful texture.',
  },
  {
    question: 'How do dermal fillers work?',
    answer:
      'Your skin naturally produces hyaluronic acid, a sugar-based compound responsible for ' +
      'delivering and maintaining moisture and nutrient levels in skin cells. ' +
      'This helps in creating volume and smoothness in your skin. Unfortunately, ' +
      'with time, your skin’s ability to manufacture its own hyaluronic acid decreases. ' +
      'When dermal fillers are injected into fine lines, wrinkles, or sunken areas of the face, ' +
      'they replenish the hyaluronic acid that has been absorbed into your body over time, helping ' +
      'you achieve a more youthful appearance.',
  },
  {
    question: 'Can dermal fillers be used around the eyes?',
    answer:
      'Dermal fillers can be used in the tear trough (under eye) area to reduce hollowing ' +
      'and dark circles under the eyes, resulting in a refreshed and rejuvenated appearance. ' +
      'Wrinkles around the eyes can be effectively treated using a combination of dermal fillers, ' +
      'muscle relaxants and Ultherapy.',
  },
]

const DermalFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Dermal Fillers - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="DERMAL FILLERS" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                <picture>
                  <source srcSet="images/blog/08.webp" type="image/webp" />
                  <source srcSet="images/blog/08.jpg" type="image/jpeg" />
                  <img
                    src="/images/blog/08.jpg"
                    className="alignright"
                    alt="dermal_fillers"
                  />
                </picture>
              </p>
              <p className="excerpt">
                Are you concerned with deep nose to mouth lines, or sunken
                cheeks? Ironically, loss of volume can be more ageing than
                wrinkles, especially on a thin face. If you have lost confidence
                in your appearance, dermal fillers are a safe and affordable
                treatment that can restore a more youthful look.
              </p>
              <p>
                Over time we lose volume from our face: our natural supply of
                hyaluronic acid diminishes and our skin becomes less firm and
                folds deepen.
              </p>
              <p>
                At Ada Aesthetics we offer premium dermal fillers containing
                hyaluronic acid to smooth and plump up the skin in a subtle way
                – we pride ourselves on a natural look. Following a
                consultation, we can devise your personalised treatment plan.
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default DermalFillers

export const pageQuery = graphql`
  query DermalFillers {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
